import {
	SET_NOTIFICATIONS_IN_PROFILE,
	FETCH_NOTIFICATIONS_IN_PROFILE,
	FETCH_NOTIFICATIONS_IN_PROFILE_SUCCESS,
	TOOGLE_LAST_PAGE_IN_NOTIFICATIONS_IN_PROFILE,
	CLEAR_NOTIFICATIONS_IN_PROFILE,
	SET_LAST_NOTIFICATIONS,
	CLEAR_LAST_NOTIFICATIONS,
	SET_IS_LOADING_NOTIFICATIONS,
	VIEW_NOTIFICATION,
	VIEW_ALL_NOTIFICATION,
} from 'constant';

const DefaultState = {
	last: [],
	all: [],
	isLoading: false,
	isLastPage: false,
	page: 0,
};

export default function userNotification(state = DefaultState, action) {
	switch (action.type) {
		case SET_LAST_NOTIFICATIONS:
			return {
				...state,
				last: [...action.last],
			};

		case CLEAR_LAST_NOTIFICATIONS:
			return {
				...state,
				last: [],
			};

		case SET_IS_LOADING_NOTIFICATIONS:
			return {
				...state,
				isLoading: action.isLoading,
			};

		case FETCH_NOTIFICATIONS_IN_PROFILE:
			return {
				...state,
				isLoading: true,
			};

		case FETCH_NOTIFICATIONS_IN_PROFILE_SUCCESS:
			return {
				...state,
				isLoading: false,
			};

		case SET_NOTIFICATIONS_IN_PROFILE:
			return {
				...state,
				all: [...state.all, ...action.all],
				page: action.page,
			};

		case TOOGLE_LAST_PAGE_IN_NOTIFICATIONS_IN_PROFILE:
			return {
				...state,
				isLastPage: true,
			};

		case CLEAR_NOTIFICATIONS_IN_PROFILE:
			return {
				...state,
				all: [],
				isLastPage: false,
				page: 0,
			};

		case VIEW_NOTIFICATION:
			return {
				...state,
				last: viewNtfHelper(state.last, action.id),
				all: viewNtfHelper(state.all, action.id),
			};

		case VIEW_ALL_NOTIFICATION:
			return {
				...state,
				last: viewNtfHelper(state.last, false),
				all: viewNtfHelper(state.all, false),
			};

		default:
			return state;
	}
}

const viewNtfHelper = (ntfs, id) => {
	return ntfs.map(item => {
		if (id) {
			if (item.id === +id) item.showed = true;
		} else {
			item.showed = true;
		}

		return item;
	});
};
