import {
	GET_LIST_WISHING_BALL,
	ADD_PHRASE_WISHING_BALL,
	EDIT_PHRASE_WISHING_BALL,
	DELETE_PHRASE_WISHING_BALL,
} from 'constant';
import { IReduxDefaultStateWishingBall } from 'shared/api/wishingBall/types';

const DefaultState: IReduxDefaultStateWishingBall = {
	list: [],
	item: null,
};

interface IAction {
	type: string;
	payload: IReduxDefaultStateWishingBall;
}

export default function wishingBall(state = DefaultState, action: IAction) {
	switch (action.type) {
		case GET_LIST_WISHING_BALL:
			return {
				...state,
				list: action.payload.list,
			};
		case ADD_PHRASE_WISHING_BALL:
			return {
				...state,
				list: [...state.list, action.payload.item],
			};
		case EDIT_PHRASE_WISHING_BALL:
			return {
				...state,
				list: state.list.map(phrase =>
					phrase.id === action.payload.item?.id
						? { ...phrase, phrase: action.payload.item.phrase }
						: phrase
				),
			};
		case DELETE_PHRASE_WISHING_BALL: {
			const newList = state.list.filter(phrase => phrase.id !== action.payload?.id);
			return {
				...state,
				list: [...newList],
			};
		}
		default:
			return state;
	}
}
