import React, { Component } from 'react';
import { Switch, Route } from 'react-router-dom';

import News, {
	Vacancies,
	Contacts,
	User,
	Group,
	Thanks,
	Club,
	Appointments,
	History,
	Documents,
	Professional,
	Walkfame,
	Help,
	Activities,
	Activity,
	Glossary,
	Guide,
	Faq,
	Search,
	Services,
	Admin,
	Forbidden,
	University,
	Literature,
	Competencies,
	Calendar,
	CalendarNY,
	Structure,
	Education,
	Strategy,
	NewsWithArrow,
	Favorites,
	Birthday,
	Gallery,
	HomePage,
	HomePageNews,
	Updates,
	Mirapolis,
	Mentoring,
	CorpValues,
	Achievements,
	Quiz,
	LTR,
	VHI,
	ChatBot,
	ChatBotList,
} from './titles';

export default class Router extends Component {
	render() {
		return (
			<Switch>
				<Route exact path="/" component={HomePage} />
				<Route exact path="/main" component={HomePage} />
				<Route path="/main/news" component={HomePageNews} />

				<Route exact path="/news" component={News} />

				<Route path="/news" component={NewsWithArrow} />

				<Route path="/user" component={User} />

				<Route path="/contacts" component={Contacts} />

				<Route path="/contacts" component={Contacts} />

				<Route path="/group" component={Group} />

				<Route path="/group-create" component={Group} />

				<Route path="/thanks" component={Thanks} />

				<Route path="/vacancies" component={Vacancies} />

				<Route path="/club" component={Club} />

				<Route path="/birthday" component={Birthday} />

				<Route path="/appointments" component={Appointments} />

				<Route path="/history" component={History} />

				<Route path="/maxiProfessional" component={Professional} />

				<Route path="/walkfame" component={Walkfame} />

				<Route path="/help" component={Help} />

				<Route path="/strategy" component={Strategy} />

				<Route exact path="/activities" component={Activities} />
				<Route path="/activities/create" component={Activities} />
				<Route path="/activities/:id" component={Activity} />

				<Route strict path="/knowledge/glossary" component={Glossary} />

				<Route strict path="/knowledge/guide" component={Guide} />

				<Route strict path="/knowledge/documents" component={Documents} />

				<Route strict path="/knowledge/faq" component={Faq} />

				<Route path="/search" component={Search} />

				<Route path="/services" component={Services} />
				<Route path="/v2/services" component={Services} />

				<Route path="/admin" component={Admin} />

				<Route path="/forbidden" component={Forbidden} />

				<Route exact path="/university/literature" component={Literature} />

				<Route path="/university" component={University} />

				<Route path="/competencies" component={Competencies} />

				<Route path="/calendar/13" component={CalendarNY} />
				<Route path="/calendar" component={Calendar} />

				<Route path="/new-structure" component={Structure} />
				<Route path="/structure" component={Structure} />

				<Route path="/education" component={Education} />

				<Route path="/favorites" component={Favorites} />

				<Route path="/gallery" component={Gallery} />

				<Route path="/updates" component={Updates} />

				<Route path="/mirapolis-mobile" component={Mirapolis} />

				<Route path="/mentoring" component={Mentoring} />

				<Route path="/corporate-values" component={CorpValues} />

				<Route path="/achievements" component={Achievements} />

				<Route path="/quiz" component={Quiz} />

				<Route path="/ltr" component={LTR} />

				<Route path="/VHI" component={VHI} />

				<Route path="/messenger/:id" component={ChatBot} />
				<Route path="/messenger" component={ChatBotList} />
			</Switch>
		);
	}
}
