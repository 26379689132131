import React, { FC, useEffect, useState } from 'react';

import { IQuestionComponentProps } from '../types';

const colors = [
	'#FFB803',
	'#FFF626',
	'#3BF568',
	'#01B813',
	'#01B3FF',
	'#0140FF',
	'#8301EA',
	'#EB0ECC',
	'#D60152',
	'#DA2A1B',
	'#FFE4E1',
	'#008000',
	'#333399',
	'#e67300',
	'#b30000',
];

export const OtherQuestion: FC<IQuestionComponentProps> = ({ question, questionNumber }) => {
	const [gradient, setGradient] = useState('');

	useEffect(() => {
		if (question.answers.length) {
			let str = 'conic-gradient(';
			let prev = 0;
			question.answers.forEach((item, index) => {
				str += `${colors[index]} ${prev}% ${prev + item.percent}%`;
				if (index !== question.answers.length - 1) str += ', ';
				prev = prev + item.percent;
			});
			str += ')';

			setGradient(str);
		}
	}, [question]);

	return (
		<div className="question-analitic">
			{questionNumber && (
				<p
					className="question-analitic__title"
					id={`pulse-survey_answer-id-${question.id}`}
				>
					{questionNumber}. {question.text}
				</p>
			)}

			<div className="question-analitic__wrap">
				<div
					className="pie-chart"
					style={{
						background: gradient,
					}}
				>
					<div className="pie-chart__bg"></div>
				</div>

				<div className="analitic-legenda">
					{question.answers.map((item, index) => (
						<div className="analitic-legenda__item" key={index}>
							<div
								className="analitic-legenda__color"
								style={{ backgroundColor: colors[index] }}
							></div>
							<p className="analitic-legenda__percent">{item.percent}%</p>
							<p className="analitic-legenda__text">{item.text}</p>
						</div>
					))}
				</div>
			</div>
		</div>
	);
};
