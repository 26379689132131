import React from 'react';
import { Link } from 'react-router-dom';

import { URL } from 'constant';

export const GalleryItem = ({ match, location, id, item, isOpenGalleryHandler }) => {
	const { id: groupId, idPost } = match.params;
	const path = match.path.replace(/:.+/gm, '');
	const pathname = groupId ? path + groupId + '/' : path;
	const search = location.search;

	return idPost ? (
		<Link
			to={`${pathname}${idPost}${search}#p${id}_i${item.id}`}
			onClick={isOpenGalleryHandler}
			className="post-image"
		>
			<span className="post-image__wrap">
				<img
					src={`${URL + item.filePath}?size=MEDIUM`}
					alt={item.fullName}
					className="post-image__img"
				/>
			</span>
		</Link>
	) : (
		<Link
			to={`${pathname}${search}#p${id}_i${item.id}`}
			onClick={isOpenGalleryHandler}
			className="post-image"
		>
			<span className="post-image__wrap">
				<img
					src={`${URL + item.filePath}?size=MEDIUM`}
					alt={item.fullName}
					className="post-image__img"
				/>
			</span>
		</Link>
	);
};
