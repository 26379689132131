import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { ChevronDown, ChevronUp } from 'react-feather';

import { IMenuItem } from './types';
import classNames from 'classnames';

interface IProps {
	item: IMenuItem;
	isMobile: boolean;
	submenu: IMenuItem[];
	elementId?: string;
}

export const MobileSubMenu = ({ item, isMobile, submenu, elementId }: IProps) => {
	const [show, setShow] = useState(false);

	return (
		<div className="navigation-menu__item">
			{item.link ? (
				<Link
					to={item.link}
					className="navigation-menu__link"
					id={elementId ? elementId : ''}
				>
					<span className="navigation-menu__text">{item.name}</span>
					{submenu && !isMobile && <ChevronDown className="navigation-menu__icon" />}
				</Link>
			) : (
				<span
					className="navigation-menu__link"
					id={elementId ? elementId : ''}
					onClick={() => setShow(prev => !prev)}
				>
					<span className="navigation-menu__text">{item.name}</span>
					{submenu &&
						(show ? (
							<ChevronUp className="navigation-menu__icon" />
						) : (
							<ChevronDown className="navigation-menu__icon" />
						))}
				</span>
			)}

			{!item.link && (
				<div
					className={classNames('navigation-mobile-submenu', {
						show,
					})}
				>
					{submenu.map(
						subItem =>
							subItem.link &&
							(subItem.url ? (
								<a
									href={subItem.link}
									className="navigation-mobile-submenu__link"
									key={subItem.id}
									id={subItem.elementId ? subItem.elementId : ''}
								>
									<span className="navigation-mobile-submenu__text">
										{subItem.name}
									</span>
								</a>
							) : (
								<Link
									to={subItem.link}
									className="navigation-mobile-submenu__link"
									key={subItem.id}
									id={subItem.elementId ? subItem.elementId : ''}
								>
									<span className="navigation-mobile-submenu__text">
										{subItem.name}
									</span>
								</Link>
							))
					)}
				</div>
			)}
		</div>
	);
};
