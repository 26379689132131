import React, { FC, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Datepicker } from '@maxi-innovation/ui-kit-frontend';

import { FormInputBlock } from 'core/components';
import { updateSurveyEndDateAction, updateSurveyStartDateAction } from 'action/survey';

export const DateFields: FC = () => {
	const [selectedStartDate, setSelectedStartDate] = useState<Date | null>();
	const [selectedEndDate, setSelectedEndDate] = useState<Date | null>();

	const dispatch = useDispatch();

	const onChangeDatePickerStart = (date: Date | null) => {
		setSelectedStartDate(date);
		const unixDate = date && date.getTime();
		dispatch(updateSurveyStartDateAction(unixDate));
	};

	const onChangeDatePickerEnd = (date: Date | null) => {
		setSelectedEndDate(date);
		const unixDate = date && date.getTime();
		dispatch(updateSurveyEndDateAction(unixDate));
	};

	return (
		<FormInputBlock>
			<div className="create-survey-date">
				<div className="create-survey-date__item">
					<Datepicker
						selected={selectedStartDate}
						error={!selectedStartDate}
						label="Дата начала опроса"
						onChange={onChangeDatePickerStart}
						name="startDate"
						showTimeSelect
						timeFormat="p"
						timeIntervals={15}
						dateFormat="Pp"
						minDate={new Date()}
						showDisabledMonthNavigation
						placeholderText="Выберите дату и время"
					/>
				</div>

				<div className="create-survey-date__item">
					<Datepicker
						selected={selectedEndDate}
						error={!selectedEndDate}
						label="Дата окончания опроса"
						onChange={onChangeDatePickerEnd}
						name="endDate"
						showTimeSelect
						timeFormat="p"
						timeIntervals={15}
						dateFormat="Pp"
						minDate={selectedStartDate ? new Date(selectedStartDate) : new Date()}
						showDisabledMonthNavigation
						placeholderText="Выберите дату и время"
					/>
				</div>
			</div>
		</FormInputBlock>
	);
};
