import {
	GET_CUR_USER_FULL_INFO,
	CLEAR_CUR_USER_FULL_INFO,
	GET_TOP_SERVICES_CATEGORIES,
	CLEAR_TOP_SERVICES_CATEGORIES,
	GET_CATEGORY_TREE_IT_SERVICES_NEW,
	GET_ALL_CATEGORIES,
} from 'constant';

const DefaultState = {
	user: null,
	topCategoriesList: null,
	technicalMsg: null,

	entities: {},
	list: {},

	listTreeNew: [],

	faq: null,
};

export default function services(state = DefaultState, action) {
	switch (action.type) {
		case GET_CUR_USER_FULL_INFO:
			return {
				...state,
				user: action.user,
			};

		case CLEAR_CUR_USER_FULL_INFO:
			return {
				...state,
				user: null,
			};

		case GET_TOP_SERVICES_CATEGORIES:
			return {
				...state,
				topCategoriesList: action.list,
			};

		case CLEAR_TOP_SERVICES_CATEGORIES:
			return {
				...state,
				topCategoriesList: null,
			};

		case GET_CATEGORY_TREE_IT_SERVICES_NEW:
			return {
				...state,
				listTreeNew: action.list,
			};

		case GET_ALL_CATEGORIES:
			return setCategoriesHelper(state, action.allCategories);

		default:
			return state;
	}
}

function setCategoriesHelper(state, data) {
	const currentState = { ...state };
	data.forEach(item => {
		currentState.entities[item.id] = { ...item };
		currentState.list[item.id] = getChildCategories(data, item.id);
	});
	return currentState;
}

function getChildCategories(data, id) {
	return data.reduce((childCat, item) => {
		if (+item.parentId === +id) {
			return [...childCat, +item.id];
		}
		return [...childCat];
	}, []);
}
