import { api } from './api';

//Список всех разделов
export const categoriesList = () => {
	return api.get('/wiki/guide/categories/get-all-list');
};

//Создание корневого раздела
//name string
//sort integer (int64)
//admin integer (int64)
//departments	array[integer]
//users array[integer]
//roles	array[integer]
export const createTopCategory = data => {
	return api.post('/wiki/guide/categories/create-top', {
		...data,
	});
};

//Получить инфу для редактирования корневой категории
//id integer (int64)
export const getTopCategory = id => {
	return api.get(`/wiki/guide/categories/get-top?id=${id}`);
};

export const getAllWithoutChildrenCategoryList = async id =>
	api
		.get('/wiki/guide/categories/get-all-without-children-list', {
			params: { id },
		})
		.then(res => res.data);

//Редактирование корневого раздела
//id (int64)
//name string
//sort integer(int64)
//admins array[integer]
//departments array[integer]
//users array[integer]
//roles array[integer]
export const editTopCategory = data => {
	return api.post('/wiki/guide/categories/edit-top', {
		...data,
	});
};

//Создание дочернего раздела
//name string
//parentId integer (int64)
//sort integer (int64)
export const createChildCategory = data => {
	return api.post('/wiki/guide/categories/create-children', {
		...data,
	});
};

//Получить инфу для редактирования дочернего раздела
//id integer (int64)
export const getChildCategory = id => {
	return api.get(`/wiki/guide/categories/get-children?id=${id}`);
};

//Редактирование корневого раздела
//id (int64)
//name string
//sort integer(int64)
//parentId integer(int64)
export const editChildCategory = data => {
	return api.post('/wiki/guide/categories/edit-children', {
		...data,
	});
};

// //Удаление любого раздела гида
//id integer (int64)
//name string
export const deleteCategory = data => {
	return api.post('/wiki/guide/categories/delete', {
		...data,
	});
};

//Добавить статью в гид
//name string
//categoryId integer (int64)
//content string
//roleIds array[integer]
//fileIds array[integer]
//tags array[string]
export const createArticle = data => {
	return api.post('/wiki/guide/create', {
		...data,
	});
};

//Получить список статей категории
export const getArticlesList = categoryId => {
	return api.get(`/wiki/guide/list-by-cat?categoryId=${categoryId}`);
};

//Обновить статью в гиде
//id integer (int64)
//name string
//categoryId integer (int64)
//content string
//roleIds array[integer]
//fileIds array[integer]
//tags array[string]
//url string
export const editArticle = data => {
	return api.post(`/wiki/guide/update`, {
		...data,
	});
};

//Перенести статью гида в архив
//id integer (int64)
export const articleToArchive = id => {
	return api.post(`/wiki/guide/delete`, {
		id,
	});
};

//Мобильная версия

export const sortArticleList = data => {
	return api.post('/wiki/guide/sort', { ...data });
};

export const getChildrenCategoryList = categoryId =>
	api
		.get('/wiki/guide/categories/get-all-children-list', {
			params: { id: categoryId },
		})
		.then(res => res.data);

export const sortCategoryList = data => api.post('/wiki/guide/categories/sort', { ...data });

// name	string
// parentId	integer (int64)
// admins	array[integer]
export const createFirstChildren = data =>
	api.post('/wiki/guide/categories/create-first-children', data);

export const updateFirstChildren = data =>
	api.post('/wiki/guide/categories/edit-first-children', data);

export const getFirstChildren = id => api.get(`/wiki/guide/categories/get-first-children?id=${id}`);

//Получить список корневых категорий

export const getTopCategoriesList = async () => {
	try {
		const response = await api.get('/wiki/guide/categories/get-top-tree');
		return response.data;
	} catch (error) {
		return {
			errorMessage: error,
			response: {},
			responseCode: 1,
		};
	}
};

export const getChildCategoriesList = async id => {
	try {
		const response = await api.get(`/wiki/guide/categories/get-children-tree?id=${id}`);
		return response.data;
	} catch (error) {
		return {
			errorMessage: error,
			response: {},
			responseCode: 1,
		};
	}
};
