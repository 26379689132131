import React, { useEffect, useState } from 'react';
import { ChevronDown } from 'react-feather';
import { Link } from 'react-router-dom';

import { getNavigationMenu } from 'shared/api/navigationMenu';
import { IMenuItem } from './types';
import { useIsMobile } from 'hooks/useIsMobile';

import './style.scss';
import { MobileSubMenu } from './mobileSubMenu';

export const NavigationMenu = () => {
	const isMobile = useIsMobile();
	const [menuItems, setMenuItems] = useState<IMenuItem[]>();

	useEffect(() => {
		(async () => {
			const { response } = await getNavigationMenu();
			setMenuItems(response);
		})();
	}, []);

	const renderMenuItem = (
		link: string | null,
		name: string,
		submenu: boolean,
		elementId: string | null
	) => {
		return link ? (
			<Link to={link} className="navigation-menu__link" id={elementId ? elementId : ''}>
				<span className="navigation-menu__text">{name}</span>
				{submenu && !isMobile && <ChevronDown className="navigation-menu__icon" />}
			</Link>
		) : (
			<span className="navigation-menu__link" id={elementId ? elementId : ''}>
				<span className="navigation-menu__text">{name}</span>
				{submenu && <ChevronDown className="navigation-menu__icon" />}
			</span>
		);
	};

	const renderSubMenu = (submenu: IMenuItem[]) => {
		return (
			<div className="navigation-submenu">
				{submenu.map(
					subItem =>
						subItem.link &&
						(subItem.url ? (
							<a
								href={subItem.link}
								className="navigation-submenu__link"
								key={subItem.id}
								id={subItem.elementId ? subItem.elementId : ''}
							>
								<span className="navigation-submenu__text">{subItem.name}</span>
							</a>
						) : (
							<Link
								to={subItem.link}
								className="navigation-submenu__link"
								key={subItem.id}
								id={subItem.elementId ? subItem.elementId : ''}
							>
								<span className="navigation-submenu__text">{subItem.name}</span>
							</Link>
						))
				)}
			</div>
		);
	};

	return (
		<div className="navigation-menu">
			{!!menuItems?.length ? (
				menuItems.map(item =>
					isMobile ? (
						<MobileSubMenu
							key={item.id}
							submenu={item.submenu}
							item={item}
							isMobile={isMobile}
						/>
					) : (
						!item.showOnlyMobile && (
							<div className="navigation-menu__item" key={item.id}>
								{renderMenuItem(
									item.link,
									item.name,
									!!item.submenu?.length,
									item.elementId
								)}

								{!!item.submenu && renderSubMenu(item.submenu)}
							</div>
						)
					)
				)
			) : (
				<div className="menu-preloading">
					<div className="menu-preloading__item"></div>
					<div className="menu-preloading__item"></div>
					<div className="menu-preloading__item"></div>
					<div className="menu-preloading__item"></div>
					<div className="menu-preloading__item"></div>
					<div className="menu-preloading__item"></div>
				</div>
			)}
		</div>
	);
};
