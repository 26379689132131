import { IGetUserClaims } from 'shared/api/claims/type';
import {
	CLAIMS_PROFILE_GET_LIST,
	CLAIMS_PROFILE_ADD_LIST,
	CLAIMS_PROFILE_CHANGE_STATUS,
	CLAIMS_PROFILE_DELETE_CLAIM,
} from '../constant';

interface IDefaultData {
	listClaims: IGetUserClaims[];
}

const DefaultState: IDefaultData = {
	listClaims: [],
};

interface IDeleteClaim {
	id: number;
}

interface IChangeStatus {
	id: number;
	status: IStatusClaims;
	approveComment: string;
}

interface IActionGetClaim {
	type: typeof CLAIMS_PROFILE_GET_LIST;
	payload: IGetUserClaims[];
}
interface IActionAddClaim {
	type: typeof CLAIMS_PROFILE_ADD_LIST;
	payload: IGetUserClaims[];
}
interface IActionChangeClaim {
	type: typeof CLAIMS_PROFILE_CHANGE_STATUS;
	payload: IChangeStatus;
}
interface IActionDeleteClaim {
	type: typeof CLAIMS_PROFILE_DELETE_CLAIM;
	payload: IDeleteClaim;
}

type IAction = IActionGetClaim | IActionAddClaim | IActionChangeClaim | IActionDeleteClaim;

export default function claimProfile(state = DefaultState, action: IAction) {
	switch (action.type) {
		case CLAIMS_PROFILE_GET_LIST:
			return { listClaims: [...action.payload] };
		case CLAIMS_PROFILE_ADD_LIST:
			return { listClaims: [...state.listClaims, ...action.payload] };
		case CLAIMS_PROFILE_CHANGE_STATUS: {
			const changeArray = state.listClaims.map(item => {
				if (item.id === action.payload.id) {
					item.status = action.payload.status;
					item.approveComment = action.payload.approveComment;
				}
				return item;
			});
			return { listClaims: changeArray };
		}
		case CLAIMS_PROFILE_DELETE_CLAIM: {
			const deleteArray = state.listClaims.filter(item => item.id !== action.payload.id);
			return { listClaims: deleteArray };
		}
		default:
			return state;
	}
}
