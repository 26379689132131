import React, { FC, useEffect, useState } from 'react';
import { RaduoButton, TextField } from '@maxi-innovation/ui-kit-frontend';

import { IQuestionComponentProps, IAnswered } from '../types';
import { URL } from 'constant';

export const SingleQuestion: FC<IQuestionComponentProps> = ({
	question,
	answeredHandler,
	error,
	defaultValue,
}) => {
	const [selectedAnswer, setSelectedAnswer] = useState<IAnswered>();
	const [ownAnswer, setOwnAnswer] = useState(false);
	const [ownAnswerError, setOwnAnswerError] = useState(false);

	useEffect(() => {
		if (defaultValue?.selectedAnswer) {
			answeredHandler(
				defaultValue?.selectedAnswer.map(item => {
					// @ts-ignore
					if (item.ownAnswer) item.text = defaultValue.textAnswer;
					return item;
				})
			);

			setSelectedAnswer(defaultValue.selectedAnswer[0]);

			setOwnAnswer(defaultValue.selectedAnswer[0].ownAnswer);
		}
	}, [defaultValue, answeredHandler]);

	const changeAnswer = (data: IAnswered) => {
		answeredHandler([data.ownAnswer ? { ...data, text: '' } : data]);
		setSelectedAnswer(data.ownAnswer ? { ...data, text: '' } : data);
		setOwnAnswer(data.ownAnswer);
	};

	const ownAnswerChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		if (!selectedAnswer) return;

		const { value } = event.currentTarget;
		setOwnAnswerError(!value);

		answeredHandler([
			{
				id: selectedAnswer.id,
				text: value,
				ownAnswer: true,
			},
		]);
	};

	return (
		<div className="question">
			{question.image && (
				<img src={URL + question.image.filePath} alt="" className="question__preview" />
			)}

			<div className="question__title">{question.text}</div>

			<div className="question-answers">
				{question.possibleAnswers.map(item => (
					<RaduoButton
						className="question-answers__item"
						key={item.id}
						onChange={() => changeAnswer(item)}
						checked={item.id === selectedAnswer?.id}
						name="question-answer"
					>
						<p className="question-answers__text">{item.text}</p>

						{item.ownAnswer && ownAnswer && (
							<TextField
								placeholder="Введите текст"
								error={ownAnswerError}
								className="question-answers__own-answer"
								name="own-answer"
								onChange={ownAnswerChange}
								defaultValue={defaultValue?.textAnswer}
							/>
						)}
					</RaduoButton>
				))}
			</div>

			{error && <div className="question-answers-error">Выберите вариант ответа</div>}
		</div>
	);
};
